/**
 * Created by bootdo.
 * 用户相关api
 */
import * as API from '../../utils/request';

export default {
    //获取用户menu
    get:params=> { return API.GET('/sysmenu/get', params)},
    //获取用户按钮权限
    authority:params=> { return API.GET('/sysmenu/authority', params)},
    //获取系统菜单
    getSYSMenu:params=> { return API.GET('/sysmenu/getSYSMenu', params)},
    //绑定菜单
    bindMenu:params=>{return API.POSTARRAY('/sysmenu/bindMenu', params)},
    //获取用户的menuList
    getMenuList:params=> { return API.GET('/sysmenu/getMenuList', params)},

    sget:params=>{return API.GET('/sysmenu/sys/get', params)},
    add:params=>{return API.POST('/sysmenu/sys/add', params)},
    update:params=>{return API.POST('/sysmenu/sys/update', params)},
    delete:params=>{return API.POST('/sysmenu/sys/delete', params)},
}
