<template>
    <div class="about">
        <v-header/>
        <v-sidebar/>
        <div class="content-box" :class="{'content-collapse':collapse}">
            <v-tags></v-tags>
            <div class="content">
                <!-- <keep-alive :include="tagsList"> -->
                <router-view v-slot="{ Component }">
                    <transition name="move" mode="out-in">
                        <component :is="Component"/>
                    </transition>
                </router-view>
                <!-- </keep-alive> -->
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
    </div>
</template>
<script>
    import vHeader from "../components/Header";
    import vSidebar from "../components/Sidebar";
    import vTags from "../components/Tags.vue";
    // import USER from '../api/base/api_user'
    import USER from '../api/sys/api_admin.js'
    import {getUrl} from '../utils/request'

    export default {
        components: {
            vHeader,
            vSidebar,
            vTags
        },
        computed: {
            collapse() {
                return this.$store.state.collapse
            }
        },
        data() {
            return {
                timer: undefined,
            }
        },
        beforeUnmount() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
         beforeCreate() {
                USER.currentUser({auth: 1}).then((res)=>{
                if (1 === res.status) {
                    this.$store.commit("update_user_auth", res.auth);
                    this.$store.commit("update_cuttuser", res.user);
                    this.init_sockert();//开启推送
                } else {
                    this.$router.push('/login');
                }
            })
        },
        mounted() {
          //  this.init_sockert();//开启推送
        },

        methods: {
            init_sockert() {
                // 开启webocket--消息推送
                // if (typeof WebSocket === 'undefined') return;
                // let socket = new WebSocket((getUrl() + '/yanzheng-web/na_websocket/' + localStorage.getItem("access-token")).replace('http', 'ws'));
                // socket.onmessage = (event) => {
                //     let msg = JSON.parse(event.data);
                //     this.$notify({title: msg.title, message: msg.content, type: 'info'});
                // }
                // socket.onopen = () => {
                // }
                // socket.onclose = () => {
                // }
                // socket.onerror = () => {
                // }
                // //保持会话心跳--防止超时关闭
                // this.timer = setInterval(() => {
                //     if (1 === socket.readyState) {
                //         socket.send(['{"uid":', this.$store.state.cuttuser.id, "}"].join(""))
                //     }
                // }, 30000)


            }
        }
    };
</script>