import * as API from '../../utils/request';

export default {
    //登录
    login: params => {
        return API.POSTARRAY('/sysadmin/na_login', params)
    },
    
    //登出
    logout: params => {
        return API.GET('/sysadmin/logout', params)
    },

    currentUser: params => {
        return API.GET('/sysadmin/currentUser', params)
    },
    
    //修改用户信息
    updateUser: params => {
        return API.POST('/sysadmin/updateUser', params)
    },
    
    delete: params => {
        return API.POST('/sysadmin/delete', params)
    },
    
    //重置密码
    resetpassword: params => {
        return API.POST('/sysadmin/resetPassword', params)
    },

    countDailyRegisterUser: params => {
        return API.GET('/sysadmin/countDailyRegisterUser', params)
    },
    countDailyPublishJob: params => {
        return API.GET('/sysadmin/countDailyPublishJob', params)
    },
    countHomeData: params => {
        return API.GET('/sysadmin/countHomeData', params)
    },
}