import {
    createRouter,
    createWebHistory
} from "vue-router";
import main from "../views/main.vue";

const routes = [{
    path: '/',
    redirect: '/dashboard'
}, {
    path: "/",
    name: "Home",
    component: main,
    children: [{
            path: "/dashboard",
            name: "Dashboard",
            meta: {
                title: '首页'
            },
            component: () => import(
                /* webpackChunkName: "dashboard" */
                // "../views/Dashboard.vue")
                "../views/BusinessHome.vue")
        },


        //顶部导航--用户信息，消息，告警
        {
            path: "/userinfo",
            name: "userinfo",
            meta: {
                title: '用戶中心'
            },
            component: () => import("../views/base/userinfo.vue")
        },
         {
            path: '/404',
            name: '404',
            meta: {
                title: '找不到页面'
            },
            component: () => import('../views/404.vue')
        }, {
            path: '/403',
            name: '403',
            meta: {
                title: '没有权限'
            },
            component: () => import('../views/403.vue')
        },

        //---------------------------------user start------------------------------------//

        {
            path: '/user/list',
            name: 'userList',
            meta: {
                title: '用户列表'
            },
            component: () => import('../views/user/list.vue')
        },
        {
            path: '/user/edit',
            name: 'userEdit',
            meta: {
                title: '用户信息'
            },
            component: () => import('../views/user/edit.vue')
        },
        {
            path: '/user/upgradeEmployer',
            name: 'upgradeEmployer',
            meta: {
                title: '升级为雇主企业'
            },
            component: () => import('../views/user/upgradeEmployer.vue')
        },
        //--------------------------------user end------------------------------------//
        //---------------------------------category start------------------------------------//

        {
            path: '/category/list',
            name: 'categoryList',
            meta: {
                title: '职位分类列表'
            },
            component: () => import('../views/category/list.vue')
        },
        //--------------------------------category end------------------------------------//
        {
            path: '/employer/list',
            name: 'employerList',
            meta: {
                title: '雇主列表'
            },
            component: () => import('../views/employer/list.vue')
        },

        //---------------------------------employer end------------------------------------//
        //---------------------------------job start------------------------------------//

        {
            path: '/job/list',
            name: 'jobList',
            meta: {
                title: '职位列表'
            },
            component: () => import('../views/job/list.vue')
        },
        {
            path: '/job/edit',
            name: 'jobEdit',
            meta: {
                title: '职位详细'
            },
            component: () => import('../views/job/edit.vue')
        },
        {
            path: '/job/upload',
            name: 'upload',
            meta: {
                title: '职位导入'
            },
            component: () => import('../views/job/upload.vue')
        },

        //--------------------------------job end------------------------------------//
    ]
}, {
    path: "/login",
    name: "Login",
    meta: {
        title: '登录'
    },
    component: () => import("../views/Login.vue")
}, {
    path: "/resetpassword",
    name: "resetpassword",
    meta: {
        title: '重置密码'
    },
    component: () => import("../views/Resetpassword.vue")
}];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | 链英`;
    const role = localStorage.getItem('access-token');
    if (!role && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
});

export default router;