import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'

const app = createApp(App)
installElementPlus(app)
if (!localStorage.getItem('access-token') && window.location.hash.indexOf("login") === -1) {router.push('/login');}
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = window.localStorage.getItem('access-token');
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';//配置请求头
axios.interceptors.request.use(
    config => {
        config.headers.Authorization = window.localStorage.getItem('access-token');
        return config
    },
    error => {
        return Promise.reject(error)
    }
);

axios.interceptors.response.use((response) => {
        if (!response.status || 1 === response.status) return response;

        // if (!response.data.code || 200 === response.data.code) return response;
    // let code = parseInt(response.data.code);
        let code=response.status
        if (403 === code) {
            router.replace({path: '403'});
        } else if (401 === code || 402 === code) {
            localStorage.user_lock = true;
            router.replace({path: "/login"});
        }
        return response;
    }, (err) => {
        store.state.loading = false;
        if (err && err.response) {
            switch (err.response.status) {
                case 401:
                    router.replace({path: "/login"});
                    break;
                case 402:
                    ElementUI.Notification({
                        title: '警告',
                        message: err.response.data.msg,
                        type: 'warning'
                    });
                    router.replace({path: "/login"});
                    break;
                case 403:
                    ElementUI.Notification({
                        title: '警告',
                        message: '没有权限',
                        type: 'warning'
                    });
                    break;
                case 503:
                    break;
                default:
                    break;
            }
        }
        return Promise.reject(err);
    }
);
app.use(store).use(router).mount('#app')

app.config.globalProperties.$getAuth = ()=> {return store.state.user_auth[window.location.pathname] || {}}
app.config.globalProperties.$getAuthByUrl = (url)=> { return store.state.user_auth[url] || {} }


