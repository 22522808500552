import axios from 'axios';
import qs from 'qs';
//dev
//let base = 'http://localhost:8088/i';
//  let base_img = 'http://localhost:8081';

//prod
let base = 'http://webapi.lianying.link/i';
let base_img = 'http://webimg.lianying.link';


//ingr
//let base = 'http://testapi.lianying.link/i';


const getUrl = function () {
    return base;
}
export const getImageUrl = function(){
    return base_img;
}

//通用方法
export const GET = (url, params) => {
    return axios.get(`${base}${url}`, {
        params: params
    }, {
        withCredentials: true
    }).then(res => res.data)
}

export const POST = (url, params) => {
    return axios.post(`${base}${url}`, params, {
        withCredentials:true
    }).then(res => res.data)
}

export const PATCH = (url, params) => {
    return axios.patch(`${base}${url}`, params, {
        withCredentials: true
    }).then(res => res.data)
}
export const PUT = (url, params) => {
    return axios.put(`${base}${url}`, params, {
        withCredentials: true
    }).then(res => res.data)
}

export const DELETE = (url, params) => {
    return axios.delete(`${base}${url}`, {
        params: params
    }, {
        withCredentials: true
    }).then(res => res.data)
}

export const POSTARRAY = (url, params) => { //提交数组
    return axios.post(`${base}${url}`, qs.stringify(params, {
        //indices: false,
        //withCredentials: true
    })).then(res => res.data)
}
export const UPLOAD = (url, params) => { //上传文件
    return axios.post(`${base}${url}`, params, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        withCredentials: true
    }).then(res => res.data)
}

export const DOWNLOADFILE = (url, params) => { //下载文件
    return axios.post(`${base}${url}`, qs.stringify(params, {
        indices: false
    }), {
        responseType: 'arraybuffer',
        withCredentials: true
    }, ).then(res => res)
}

export const exportBlob = (url, params) => { //export文件
    return axios.post(`${base}${url}`, {
                params,
                responseType: "blob"
            }, {
        withCredentials: true
    },).then(res => res.data)
}


const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: 'http://127.0.0.1:8200',
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);
export {
    getUrl
};
export default service;